<template>
  <div class="mb-12">
    <SubHeader :type="$t('navbar.projects')" />
    <div class="container-xl py-2 mx-auto w-full my-4 flex flex-col">
      <div>
        <div class="bg-gray-200 md:rounded-lg my-5 py-3 md:py-0">
          <div class="form-control px-4 py-2 md:py-5">
            <label class="label pt-0">
              <span class="label-text">Keyword</span>
            </label>
            <input type="text" :placeholder="$t('adminProjects.search')" class="dl-input max-w-md bg-white" v-model="filterKeyword" v-debounce:700ms="search" />
          </div>
        </div>

        <div class="w-full mb-20">
          <div class="mx-auto">
            <div class="flex flex-col">
              <div v-if="$store.state.AdminProjects.loading" class="mt-12 w-full flex items-center justify-center">
                <Loader :onlyLoader="true" />
              </div>
              <div v-if="!$store.state.AdminProjects.loading">
                <div class="mb-2 text-right">
                  <button @click="donwloadExcel()" class="btn btn-info btn-xs no-animation">
                    {{ $t("adminProjects.export") }}
                  </button>
                </div>
                <PaginationSelect :current_page="$store.state.AdminProjects.meta.current_page" :from="$store.state.AdminProjects.meta.from" :last_page="$store.state.AdminProjects.meta.last_page" :path="$store.state.AdminProjects.meta.path" :per_page="$store.state.AdminProjects.meta.per_page" :to="$store.state.AdminProjects.meta.to" :total="$store.state.AdminProjects.meta.total" @change-page="paginationChange" />
                <div class="py-2 align-middle inline-block min-w-full" v-if="$store.state.AdminProjects.data.length">
                  <div class="border border-gray-200 sm:rounded-lg">
                    <table class="w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'id' ? '-id' : 'id')">
                              <span>{{ $t("adminProjects.id") }}</span>
                              <TableSortDesc v-if="sort == '-id'" />
                              <TableSortAsc v-if="sort == 'id'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'is_active' ? '-is_active' : 'is_active')">
                              <span>{{ $t("adminProjects.state") }}</span>
                              <TableSortDesc v-if="sort == '-is_active'" />
                              <TableSortAsc v-if="sort == 'is_active'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'is_premium' ? '-is_premium' : 'is_premium')">
                              <span>VERSION</span>
                              <TableSortDesc v-if="sort == '-is_premium'" />
                              <TableSortAsc v-if="sort == 'is_premium'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'company' ? '-company' : 'company')">
                              <span>{{ $t("adminProjects.company") }}</span>
                              <TableSortDesc v-if="sort == '-company'" />
                              <TableSortAsc v-if="sort == 'company'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'name' ? '-name' : 'name')">
                              <span>{{ $t("adminProjects.name") }}</span>
                              <TableSortDesc v-if="sort == '-name'" />
                              <TableSortAsc v-if="sort == 'name'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase text-center">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'count_profiles' ? '-count_profiles' : 'count_profiles')">
                              <svg class="inline align-top transition-all hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="24" width="24" focusable="false">
                                >
                                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                              </svg>
                              <TableSortDesc v-if="sort == '-count_profiles'" />
                              <TableSortAsc v-if="sort == 'count_profiles'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase text-center">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'count_content' ? '-count_content' : 'count_content')">
                              <svg class="inline align-top transition-all hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="24" width="24" focusable="false">
                                <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                              </svg>
                              <TableSortDesc v-if="sort == '-count_content'" />
                              <TableSortAsc v-if="sort == 'count_content'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase text-center">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'count_segments' ? '-count_segments' : 'count_segments')">
                              <svg class="inline align-top transition-all hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="24" width="24" focusable="false">
                                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                              </svg>
                              <TableSortDesc v-if="sort == '-count_segments'" />
                              <TableSortAsc v-if="sort == 'count_segments'" />
                            </div>
                          </th>
                          <th scope="col" class="px-6 py-3 text-xs font-semibold text-gray-900 uppercase text-center">
                            <div class="flex gap-2 cursor-pointer" @click="sortAndRefresh(sort == 'count_integrations' ? '-count_integrations' : 'count_integrations')">
                              <svg class="inline align-top transition-all hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" height="24" width="24" focusable="false">
                                <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                              </svg>
                              <TableSortDesc v-if="sort == '-count_integrations'" />
                              <TableSortAsc v-if="sort == 'count_integrations'" />
                            </div>
                          </th>
                          <th scope="col" class="px-2 py-3 rounded-t-lg"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(project, projectIndex) in $store.state.AdminProjects.data" :key="projectIndex" class="hover:bg-gray-100 transition" @dblclick="$router.push({ name: 'AdminProjectsShow', params: { id: project.id } })">
                          <td class="px-6 py-3 whitespace-nowrap" :class="[projectIndex == $store.state.AdminProjects.data.length - 1 ? 'rounded-b-lg' : '']">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900"> #{{ project.id }} </span>
                          </td>
                          <td class="px-6 py-3 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-200 text-green-800 uppercase" v-if="project.is_active">{{ $t("adminProjects.active") }}</span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-200 text-red-800 uppercase" v-if="!project.is_active">{{ $t("adminProjects.inactive") }}</span>
                          </td>
                          <td class="px-6 py-3 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 uppercase" v-if="project.is_premium">PREMIUM</span>
                            <span class="px-2 inline-flex text-xs text-center leading-5 font-semibold rounded-full bg-gray-200 text-gray-800 uppercase" v-if="!project.is_premium">FREEMIUM</span>
                          </td>
                          <td class="px-6 py-3 whitespace-nowrap">
                            <div class="text-sm text-gray-700 font-semibold">{{ project.company }}</div>
                          </td>
                          <td class="px-6 py-3 whitespace-nowrap">
                            <div class="text-sm text-gray-700 font-semibold">{{ project.name }}</div>
                          </td>
                          <td class="px-6 py-3 whitespace-nowrap text-left text-sm text-gray-500">{{ $filters.number(project.count_profiles) }}</td>
                          <td class="px-6 py-3 whitespace-nowrap text-left text-sm text-gray-500">{{ $filters.number(project.count_content) }}</td>
                          <td class="px-6 py-3 whitespace-nowrap text-left text-sm text-gray-500">{{ $filters.number(project.count_segments) }}</td>
                          <td class="px-6 py-3 whitespace-nowrap text-left text-sm text-gray-500">{{ $filters.number(project.count_integrations) }}</td>
                          <td class="pr-6 py-3 text-right" :class="[projectIndex == $store.state.AdminProjects.data.length - 1 ? 'rounded-b-lg' : '']">
                            <Dropdown>
                              <template v-slot:buttons>
                                <router-link :to="{ name: 'Admin project show', params: { id: project.id } }" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800" role="menuitem"> <DropdownOptionShow /> {{ $t("adminProjects.see") }} </router-link>

                                <router-link :to="{ name: 'Admin project edit', params: { id: project.id } }" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800" role="menuitem"> <DropdownOptionEdit /> {{ $t("adminProjects.edit") }} </router-link>

                                <a href="javascript:void(0);" @click="remove(project.id)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-800 group" role="menuitem"> <DropdownOptionDelete /> {{ $t("adminProjects.delete") }} </a>
                              </template>
                            </Dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-else>
                  <div class="flex flex-col items-center mt-20">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-32 text-gray-300">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    <span class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminProjects.notfound") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader"
import Loader from "@/components/UI/Loader"
import PaginationSelect from "@/components/PaginationSelect"
import TableSortDesc from "@/components/Table/TableSortDesc"
import TableSortAsc from "@/components/Table/TableSortAsc"
import Dropdown from "@/components/Dropdowns/Dropdown"
import DropdownOptionDelete from "@/components/Dropdowns/DropdownOptionDelete"
import DropdownOptionEdit from "@/components/Dropdowns/DropdownOptionEdit"
import DropdownOptionShow from "@/components/Dropdowns/DropdownOptionShow"
import axiosClient from "@/config/AxiosClient"

export default {
  name: "AdminProjects",
  data() {
    return {
      filterKeyword: "",
      sort: "-count_profiles",
      limit: 50,
      default: {
        sort: "-count_profiles",
        limit: 50
      }
    }
  },
  components: {
    SubHeader,
    Loader,
    PaginationSelect,
    TableSortDesc,
    TableSortAsc,
    Dropdown,
    DropdownOptionDelete,
    DropdownOptionEdit,
    DropdownOptionShow
  },
  async mounted() {
    this.search()
  },
  methods: {
    sortAndRefresh(sort) {
      this.sort = sort
      this.search()
    },

    search: function (config = {}) {
      let filter = {}

      if (this.filterKeyword) filter["filter[id|company|name][like]"] = `%${this.filterKeyword}%`

      filter.page = config.page || 1
      filter.sort = this.sort || this.default.sort
      filter.limit = this.limit || this.default.limit

      this.$store.dispatch("AdminProjects/list", filter)
    },

    paginationChange: function (page) {
      this.search({ page })
    },

    remove: function (id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          let status = this.$store.dispatch("AdminProjects/remove", id)
          if (status) {
            this.search()
            this.$toast.success("Se elimino con éxito")
          } else {
            this.$toast.error("Ocurrió un error, por favor vuelva intentar")
          }
        }
      })
    },

    donwloadExcel() {
      let accessToken = localStorage.getItem("accessToken")
      let config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: "blob"
      }
      axiosClient.get(`/api/2.0/admin/project/integration/export`, config).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "AdminProjectsIntegrations.xlsx") //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>
