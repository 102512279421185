<template>
  <div class="border-t bg-white shadow">
    <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
      <div>
        <h4 class="text-xl font-semibold leading-tight text-gray-800">{{ type }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubHeader",
  props: ["type"]
}
</script>
