<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 stroke-current text-gray-500 hover:bg-gray-100 hover:text-gray-800" viewBox="0 0 24 24" stroke-width="1" stroke="fill" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
  </svg>
</template>

<script>
export default {
  name: `DropdownOptionEdit`,
};
</script>