<template>
  <div class="w-full" v-if="total">
    <div>
      <div class="bg-gray-200 px-4 py-3 flex items-center justify-between border-t sm:px-6 md:rounded-lg">
        <div class="flex-1 flex justify-between sm:hidden">
          <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
            {{ t("pagination.previous", {}, { locale: $store.state.Langs.selected.lang }) }}
          </a>
          <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
            {{ t("pagination.next", {}, { locale: $store.state.Langs.selected.lang }) }}
          </a>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p class="text-sm text-gray-700">
              {{ t("pagination.showing", {}, { locale: $store.state.Langs.selected.lang }) }}
              <span class="font-medium">{{ from }}</span>
              {{ t("pagination.to", {}, { locale: $store.state.Langs.selected.lang }) }}
              <span class="font-medium">{{ to }}</span>
              {{ t("pagination.of", {}, { locale: $store.state.Langs.selected.lang }) }}
              <span class="font-medium">{{ total }}</span>
              {{ t("pagination.results", {}, { locale: $store.state.Langs.selected.lang }) }}
            </p>
          </div>
          <div>
            <nav v-if="last_page > 1" class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
              <a href="javascript:void(0)" @click="selectItem('prev')" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500" :class="[current_page == 1 ? 'opacity-30 cursor-not-allowed' : 'hover:bg-gray-50']">
                <span class="sr-only">{{ t("pagination.previous", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
              </a>

              <select v-if="dropdown" class="relative inline-flex items-center px-5 text-center py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 appearance-none focus:outline-none" @change="selectItem" v-model="selectPage">
                <option v-for="(item, itemIndex) in items" :key="itemIndex">{{ item }}</option>
              </select>

              <a v-if="last_page > 1" href="javascript:void(0)" @click="selectItem('next')" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500" :class="[current_page == last_page ? 'opacity-30 cursor-not-allowed' : 'hover:bg-gray-50']">
                <span class="sr-only">{{ t("pagination.next", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"

export default {
  name: `Pagination`,
  props: ["current_page", "from", "last_page", "per_page", "to", "total", "dropdown"],
  emits: ["change-page"],
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  data: function () {
    return {
      default: {
        pages: 100
      },
      items: [],
      selectPage: null
    }
  },
  mounted: function () {
    this.selectPage = this.current_page
    this.calculateItems()
  },
  methods: {
    calculateItems: function () {
      this.items = []
      for (let startCount = 1; startCount <= this.last_page; startCount++) {
        this.items.push(startCount)
      }
    },
    selectItem: function (page) {
      if (page == "prev" && this.current_page == 1) return
      if (page == "next" && this.current_page == this.last_page) return
      if (page == "prev") this.selectPage = this.current_page - 1
      else if (page == "next") this.selectPage = this.current_page + 1
      this.$emit("change-page", this.selectPage)
    }
  }
}
</script>
