<template>
  <div class="relative inline-block text-left" v-click-away="closeShowDropdown">
    <div>
      <button type="button" class="rounded border border-gray-300 shadow-sm px-2.5 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-200" id="options-menu" aria-haspopup="true" aria-expanded="true" @click.stop="openShowDropdown">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>
    <TransitionFade2>
      <div class="origin-top-right absolute z-40 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" v-if="showDropdown">
        <div class="py-2 px-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <slot name="buttons"> </slot>
        </div>
      </div>
    </TransitionFade2>
  </div>
</template>

<script>
import TransitionFade2 from "@/components/TransitionFade2"
export default {
  name: `Dropdown`,
  components: {
    TransitionFade2
  },
  data: function () {
    return {
      showDropdown: false
    }
  },
  methods: {
    openShowDropdown: function () {
      this.showDropdown = !this.showDropdown
    },
    closeShowDropdown: function () {
      this.showDropdown = false
    }
  }
}
</script>
