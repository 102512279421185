<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 stroke-current text-gray-500 hover:bg-gray-100 hover:text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
</svg>
</template>

<script>
export default {
  name: `DropdownOptionShow`,
};
</script>